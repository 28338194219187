<template>
  <div class="flex items-center justify-between">
    <i18n-t keypath="labels.pagination" scope="global" tag="p">
      <template v-slot:start>
        <span>{{ (modelValue - 1) * perPage + 1 }}</span>
      </template>
      <template v-slot:end>
        <span>{{
          modelValue * perPage > rows ? rows : modelValue * perPage
        }}</span>
      </template>
      <template v-slot:length>
        <span>{{ rows }}</span>
      </template>
    </i18n-t>
    <div class="flex overflow-hidden rounded-md border border-slate-300">
      <button
        class="focus:outline-none flex h-8 w-8 cursor-pointer items-center justify-center rounded-l-md bg-white hover:bg-indigo-100 focus:ring focus:ring-inset focus:ring-indigo-300 disabled:cursor-default"
        :disabled="modelValue == 1"
        @click="emit('update:modelValue', --modelValue)"
      >
        <ChevronLeftIcon class="h-4 w-4" />
      </button>
      <button
        v-for="page in pagesInRange"
        :key="page"
        class="focus:outline-none flex h-8 w-8 cursor-pointer items-center justify-center border-l border-slate-300 focus:ring focus:ring-inset focus:ring-indigo-300"
        :class="
          page == modelValue
            ? 'bg-indigo-500 text-white hover:bg-indigo-600'
            : 'bg-white hover:bg-indigo-100'
        "
        @click="emit('update:modelValue', page)"
      >
        {{ page }}
      </button>
      <button
        class="focus:outline-none flex h-8 w-8 cursor-pointer items-center justify-center rounded-r-md border-l bg-white hover:bg-indigo-100 focus:ring focus:ring-inset focus:ring-indigo-300 disabled:cursor-default"
        :disabled="modelValue == pages"
        @click="emit('update:modelValue', ++modelValue)"
      >
        <ChevronRightIcon class="h-4 w-4" />
      </button>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
import { computed, watch } from "vue";
export default {
  components: { ChevronLeftIcon, ChevronRightIcon },
  props: {
    modelValue: {
      required: true,
      type: Number,
    },
    perPage: {
      required: true,
      type: Number,
    },
    rows: {
      required: true,
      type: Number,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const pages = computed(() => {
      return Math.ceil(props.rows / props.perPage);
    });

    const pagesInRange = computed(() => {
      let start = props.modelValue - 2;
      start = start < 1 ? 1 : start;
      let end = start + 4;
      end = end > pages.value ? pages.value : end;
      start = start != end - 4 ? end - 4 : start;
      start = start < 1 ? 1 : start;

      let array = [];
      for (let i = start; i <= end; i++) {
        array.push(i);
      }
      return array;
    });

    watch(
      () => props.rows,
      () => {
        if (props.modelValue > pages.value) emit("update:modelValue", 1);
      }
    );

    return {
      emit,
      pages,
      pagesInRange,
    };
  },
};
</script>
