<template>
  <input
    class="focus:outline-none block rounded-md border py-1 px-2 text-slate-500 shadow-sm"
    :class="
      invalid
        ? 'border-2 border-rose-600 focus:border-rose-500 focus:ring focus:ring-rose-200'
        : 'border border-slate-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200'
    "
    :id="id"
    :placeholder="placeholder"
    :type="type"
    :value="modelValue"
    @input="emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    id: {
      required: false,
      type: String,
    },
    invalid: {
      default: false,
      required: false,
      type: Boolean,
    },
    modelValue: {
      required: true,
      type: [Number, String],
    },
    placeholder: {
      required: false,
      type: String,
    },
    type: {
      default: "text",
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup(_, { emit }) {
    return {
      emit,
    };
  },
};
</script>
