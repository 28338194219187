<template>
  <div>
    <div v-if="showHeader">
      <div class="p-3">
        <div v-if="loading">
          <skeleton class="h-9 w-full" />
        </div>
        <div v-else class="flex items-center justify-between gap-2">
          <div class="flex flex-col gap-x-2 sm:flex-row sm:items-center">
            <div v-if="searchable" class="relative w-full">
              <form-input
                class="w-40"
                v-model="searchTerm"
                :placeholder="t('form.placeholders.search')"
                type="text"
              />
              <MagnifyingGlassIcon
                class="absolute top-1/2 right-2 h-4 w-4 -translate-y-1/2 transform"
              />
            </div>
            <div class="flex gap-2">
              <slot name="filters" />
            </div>
          </div>
          <slot name="header" />
        </div>
      </div>
    </div>
    <div class="mx-2 overflow-x-auto" :class="{ 'border-t-2': showHeader }">
      <table class="w-full" :class="{ 'mb-2': !pagination }">
        <thead class="border-b-2">
          <tr>
            <th
              v-for="field in fields"
              :key="field.key"
              class="p-3 font-semibold"
            >
              <div
                v-if="sortFields.includes(field.key)"
                class="flex cursor-pointer items-center"
                @click="sortItems(field.key)"
              >
                <slot :name="`head(${field.key})`" :field="field">
                  {{ t(`labels.${field.label}`) }}
                </slot>
                <div
                  v-if="currentSortField == field.key"
                  class="ml-2 rounded-md border border-slate-500 p-1.5 text-slate-500"
                >
                  <ChevronUpIcon v-if="ascending" class="h-3 w-3" />
                  <ChevronDownIcon v-else class="h-3 w-3" />
                </div>
                <div
                  v-else
                  class="ml-2 rounded-md border border-slate-300 p-1.5 text-slate-500"
                >
                  <ChevronDownIcon class="h-3 w-3" />
                </div>
              </div>
              <div v-else class="flex cursor-pointer items-center">
                <slot :name="`head(${field.key})`" :field="field">
                  {{ t(`labels.${field.label}`) }}
                </slot>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, itemIndex) in loading
              ? Array(perPage)
              : paginatedItems"
            :key="itemIndex"
            class="cursor-pointer border-t bg-white hover:bg-indigo-100"
            @click="item.id ? emit('row-click', item.id) : null"
          >
            <td
              v-for="field in fields"
              :key="field.key"
              class="p-3 first:rounded-l-md first:font-bold last:rounded-r-md"
            >
              <div v-if="loading">
                <skeleton class="h-6 w-full" />
              </div>
              <slot
                v-else
                :name="`cell(${field.key})`"
                :value="item[field.key]"
                :item="item"
              >
                {{ item[field.key] }}
              </slot>
            </td>
          </tr>
          <tr v-if="!paginatedItems.length && !loading">
            <td :colspan="fields.length">
              <div class="flex h-20 items-center justify-center">
                <slot name="empty">
                  {{ t("labels.empty_table") }}
                </slot>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <div v-if="pagination" class="mx-2 border-t-2 py-3 px-1">
        <div v-if="loading">
          <skeleton class="h-9 w-full" />
        </div>
        <pagination
          v-else
          v-model="currentPage"
          :perPage="perPage"
          :rows="sortedItems.length"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/vue/24/outline";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import filter from "../../utils/filterUtils";
import sort from "../../utils/sortUtils";
import FormInput from "../form/FormInput.vue";
import Skeleton from "../loading/Skeleton.vue";
import Pagination from "../pagination/Pagination.vue";
export default {
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    FormInput,
    MagnifyingGlassIcon,
    Pagination,
    Skeleton,
  },
  props: {
    ascending: {
      default: true,
      required: false,
      type: Boolean,
    },
    fields: {
      required: true,
      type: Array[Object],
    },
    items: {
      required: true,
      type: Array[Object],
    },
    loading: {
      required: true,
      type: Boolean,
    },
    pagination: {
      default: false,
      required: false,
      type: Boolean,
    },
    perPage: {
      required: false,
      type: Number,
    },
    searchable: {
      default: false,
      required: false,
      type: Boolean,
    },
    searchFields: {
      required: false,
      type: Array[String],
    },
    showHeader: {
      default: false,
      required: false,
      type: Boolean,
    },
    sortBy: {
      required: false,
      type: String,
    },
    sortFields: {
      required: false,
      type: Array[String],
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const searchTerm = ref("");

    const currentPage = ref(1);

    const currentSortField = ref(props.sortBy);

    const ascending = ref(false);

    const filteredItems = computed(() =>
      filter(props.items, searchTerm.value, props.searchFields)
    );

    const sortedItems = computed(() =>
      sort(filteredItems.value, ascending.value, currentSortField.value)
    );

    const paginatedItems = computed(() => {
      const start = (currentPage.value - 1) * props.perPage;
      const end = currentPage.value * props.perPage;
      return sortedItems.value.slice(start, end);
    });

    const sortItems = (sortField) => {
      if (sortField == currentSortField.value) {
        ascending.value = ascending.value ? false : true;
      } else {
        ascending.value = true;
      }
      currentSortField.value = sortField;
    };

    onMounted(() => {
      if (props.sortBy) {
        ascending.value = !props.ascending;
        sortItems(props.sortBy);
      }
    });

    return {
      ascending,
      currentPage,
      currentSortField,
      emit,
      searchTerm,
      sortedItems,
      sortItems,
      paginatedItems,
      t,
    };
  },
};
</script>
